.ng-select .ng-select-container {
  border-color: #ebedf2;
}
.ng-dropdown-panel .ng-dropdown-footer {
  border-top: 1px solid #ccc;
  padding: 0;
}
.is--cursor-pointer:hover {
  cursor: pointer;
}

ymaps.ymaps-2-1-68-search.ymaps-2-1-68-search_layout_normal.ymaps-2-1-68-searchbox__normal-layout {
  width: 100%;
  min-width: 480px;
}

.delivery-map-container-inner {
  width: 100%;
  height: 30rem;
  height: 40vh;
}

.m-dropdown__item {
  padding: 0 20px;
}

.m-dropdown__item:hover {
  color: #333;
  text-decoration: underline;
}

.m-dropdown__item:last-child {
  padding-bottom: 7px;
}

.m-dropdown__item:first-child {
  padding-top: 7px;
}

.m-dropdown .m-dropdown__wrapper .m-dropdown__body {
  padding: 10px 20px;
  font-size: 1rem;
}

.hc-multiselect .selected-list .c-btn {
  padding: 0.65rem 1.25rem!important;
  box-shadow: none!important;
  line-height: normal;
  border: 1px solid #ebedf2!important;
}

.hc-multiselect .selected-list .c-list .c-token {
  line-height: normal;
}
.hc-multiselect .selected-list .c-list .c-token .c-label {
  font-size: 12px;
  text-transform: lowercase;
}

@media (max-width: 992px) {
  .is--responsive-table {
    display: block;
    overflow: hidden;
    overflow-x: auto;
    height: auto;
  }
}

.m-animate-shake {
  -webkit-animation: m-animate-shake 0.1s ease-in 0.1s infinite alternate;
  -moz-animation: m-animate-shake 0.1s ease-in 0.1s infinite alternate;
  -ms-animation: m-animate-shake 0.1s ease-in 0.1s infinite alternate;
  -o-animation: m-animate-shake 0.1s ease-in 0.1s infinite alternate;
  animation: m-animate-shake 0.1s ease-in 0.1s infinite alternate;
  animation-fill-mode: initial;
}


@keyframes m-animate-shake {
from {
  -webkit-transform: rotate(13deg);
  -moz-transform: rotate(13deg);
  -ms-transform: rotate(13deg);
  -o-transform: rotate(13deg);
  transform: rotate(13deg); }
to {
  -webkit-transform-origin: center center;
  -webkit-transform: rotate(-13deg);
  -moz-transform: rotate(-13deg);
  -ms-transform: rotate(-13deg);
  -o-transform: rotate(-13deg);
  transform: rotate(-13deg); } }

.table--no-border td {
  border-top: 0;
}

.m-input--mini {
  width: 75px;
  display: inline-block;
  padding: 0.65rem 0.5rem;
}

.is--hidden {
  visibility: hidden;
  height: 0;
  padding: 0!important;
}

.hc-loader__bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, .22);
}

@media (min-width: 768px) {
  .hc-main-content {
    margin-bottom: 40px;
  }
}

@media (min-width: 993px) {
  .hc-main-content {
    width: calc(100% - 230px);
  }

  .m-aside-left--minimize .hc-main-content {
    width: calc(100% - 70px);
  }
}

.m-datatable__table {
  word-break: initial;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

@media (max-width: 575px) {
  .m-widget4 .m-widget4__item {
    width: 100%;
  }
}

.is--danger {
  color: #f4516c;
}


.hc-button {
  font-size: 15px;
  font-weight: 500;
  border-radius: 5px;
  padding: 6px 17px;
  border: 0;
  height: 40px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
}

.hc-button + .hc-button {
  margin-left: 10px;
}

.hc-button:hover {
  cursor: pointer;
}

.hc-button:disabled {
  background: #F2F3F8;
  color: #B3B3B3;
  border-color: #E0E0E0;
}

.hc-button--primary {
  color: #fff;
  background: #0788FF;
}

.hc-button--success {
  background: #D5FFF0;
  color: #00C67F;
  padding: 8px 20px 7px;
  font-size: 14px;
  border: 2px solid #00C67F;
}

.hc-button--secondary {
  background: #D0E9FF;
  color: #0788FF;
  padding: 8px 20px 7px;
  font-size: 14px;
  border: 2px solid #0788FF;
}

.hc-button--danger {
  color: #F84445;
  background: #FFD7D8;
  border: 2px solid #F84445;
}

.hc-title {
  font-weight: 500;
  font-size: 30px;
  line-height: normal;
  color: #0788FF;
}

.hc-title--small {
  font-weight: 600;
  font-size: 22px;
}

.hc-portlet {
  background: #FFFFFF;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 25px;
  margin-bottom: 25px;
}

.hc-textarea {
  background-color: #F2F3F8;
  border-radius: 5px;
  margin: 10px 0;
  padding: 10px;
  border: 0;
  width: 100%;
  max-width: 100%;
}

.clickable {
  cursor: pointer;
}

.modal--check .modal-dialog {
  max-width: 380px;
  margin: 30px auto;
  position: relative;
}

.modal--check .modal-dialog::after,
.modal--check .modal-dialog::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 14px;
  left: 0;
  bottom: -14px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAOCAYAAAA8E3wEAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABjSURBVHgBvc1RDYAwDEXRJwUJSKoEJNRpJSChsAT4YFnWbW1vcr8PVJU1J7nfUEpA5cPeAlGpsED0bGIBaMF2WHJA7ZgDOo4tooSVBlGCR0aU4FkHPRBRA2VE9kMZGT0oY6ILhwQzghbExowAAAAASUVORK5CYII=');
  background-repeat: repeat-x;
}

.modal--check .modal-dialog::before {
  bottom: initial;
  top: -14px;
  transform: rotate(180deg);
}

.modal--check .modal-content {
  border-radius: 0;
  padding: 15px 10px;
  box-shadow: none;
}

@media print {
  html {
    height: auto !important;
  }

  @page {
    size: auto;
    margin: 0;
  }

  html, body {
    background: transparent !important;
    box-shadow: none !important;
    text-shadow: none !important;
    position: static !important;
    color: #000 !important;
    font-family: arial, sans-serif;
  }

  body {
    width: 76mm;
    margin: 0;
    display: block;
  }

  body * {
    font-size: 11px !important;
    background: transparent !important;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  .m-aside-left, .m-footer, .m-header, .m-subheader, .m-content, #toast-container, .modal {
    display: none !important;
  }

  .m-body, .m-header--fixed .m-body, .m-body .m-wrapper, .m-page {
    margin: 0 !important;
    padding: 0 !important;
    display: initial !important;
    flex: none !important;
  }

  app-home {
    visibility: hidden !important;
  }
}
